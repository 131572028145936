// Generated by ReScript, PLEASE EDIT WITH CARE

import * as IconFile from "../../../styleguide/icons/IconFile.res.js";
import * as IconView from "../../../styleguide/icons/IconView.res.js";
import * as IconDelete from "../../../styleguide/icons/IconDelete.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ResourceItem from "../../../models/ResourceItem.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ResourceNewFormScss from "./ResourceNewForm.scss";

var css = ResourceNewFormScss;

function ResourceDocument$DocumentRow(props) {
  var onDelete = props.onDelete;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(IconFile.make, {
                              size: "LG",
                              color: "DarkGray"
                            }),
                        JsxRuntime.jsx("p", {
                              children: props.fileName,
                              className: css.fileNameText
                            })
                      ],
                      className: css.flexContainer
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("p", {
                              children: ResourceItem.$$Document.sizeText(props.byteSize),
                              className: css.fileSizeText
                            }),
                        JsxRuntime.jsx(IconView.make, {
                              size: "LG",
                              color: "DarkGray",
                              className: css.iconView
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(IconDelete.make, {
                                    size: "LG",
                                    color: "DarkGray",
                                    viewBoxSize: "20"
                                  }),
                              className: css.iconDelete,
                              onClick: (function (param) {
                                  onDelete();
                                })
                            })
                      ],
                      className: css.flexContainer
                    })
              ],
              className: css.documentRow
            });
}

var DocumentRow = {
  make: ResourceDocument$DocumentRow
};

function ResourceDocument(props) {
  var removeDocument = props.removeDocument;
  var $$document = props.document;
  var tmp;
  if ($$document !== undefined) {
    var $$document$1 = Caml_option.valFromOption($$document);
    tmp = JsxRuntime.jsx(ResourceDocument$DocumentRow, {
          fileName: $$document$1.name,
          byteSize: $$document$1.size | 0,
          onDelete: (function () {
              removeDocument($$document$1);
            })
        }, "recently-uploaded-document-" + $$document$1.name);
  } else {
    tmp = null;
  }
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(tmp)
            });
}

var make = ResourceDocument;

export {
  css ,
  DocumentRow ,
  make ,
}
/* css Not a pure module */
